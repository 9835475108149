<template>
  <div>

    <HistoryEditorModal
      ref="historyEditorModal"
      :history="selected_history"
    />

    <b-modal ref="selectUserOrMember" :title="$t('HISTORY.DETAILS')">
      <UserDetails
        v-if="user_id"
        :user_id="user_id"
      />
<!--      TODO: rollback -->
<!--        v-if="member_id"-->
      <MemberDetails
        :member_id="member_id"
      />
    </b-modal>

    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('SHOP_ORDER.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>

      </b-col>


      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 250]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            aria-controls="history-table"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="history-table"
      class="table-striped"
      :items="ajax_pagination"
      :fields="fields"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
      ref="historyTable"
      @filtered="on_filtered"
    >
      <template #cell(empty)="row">
        <div class='d-flex justify-content-end'>
          <a
            v-if="row.item.type !== 'DELETE_MEMBER'"
            href="#"
            class="btn btn-icon btn-light btn-sm mx-3"
            @click.prevent="history_selected(row.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>

      <template #cell(created_by)="row">
        <div v-if="row.item.type !== 'DELETE_MEMBER' && row.item.data && (row.item.data.user_id || row.item.data.member_id)">
          <a
            href="#"
            @click.prevent="show_user_or_member(row.item)"
          >
            {{ row.item.data.user_id ? 'A ' +row.item.data.user_id : 'M ' + row.item.data.member_id }}
          </a>

        </div>
      </template>
    </b-table>

    <p v-if="total_rows === 0">{{ $t('HISTORY.NO_FOUND') }}</p>
    

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import UserDetails from './UserDetails.vue';
import HistoryEditorModal from './HistoryEditorModal.vue';
import MemberDetails from '@/view/pages/ml/history/MemberDetails.vue';

export default {
  name: 'HistoryTable',
  components: {
    UserDetails,
    HistoryEditorModal,
    MemberDetails,
},
  mixins: [ toasts ],
  props: ['group'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods']),
    fields() {
      return [
        {
          key: 'created_at',
          label: this.$t('HISTORY.CREATED_AT'),
          tdClass: 'td-overflow'
        },
        this.group === 'MEMBER' ?
          {
            key: 'member',
            label: this.$t('MEMBER.NAME'),
            tdClass: 'td-overflow',
            formatter: (_, __, item) => {
              if (item.type === 'DELETE_MEMBER') {
                return item.data.public_id;
              }
              
              return item.member.firstname + ' ' + item.member.lastname;
            },
          } :
          {
            key: 'user',
            label: this.$t('MEMBER.NAME'),
            tdClass: 'td-overflow',
            formatter: (_, __, item) => {
              return item.user ? item.user.firstname + ' ' + item.user.lastname : '-';
            },
          },
        {
          key: 'type',
          label: this.$t('HISTORY.TYPE'),
          tdClass: 'td-overflow',
          formatter: (_, __, item) => {
            return this.$t('HISTORY.HISTORY_TYPE.'+item.type.toUpperCase());
          },
        },
        {
          key: 'created_by_type',
          label: this.$t('HISTORY.CREATED_BY_TYPE'),
          tdClass: 'td-overflow',
          formatter: (_, __, item) => {
            if (item.data) {
              return item.data.user_id ? this.$t('HISTORY.USER') : this.$t('HISTORY.MEMBER');
            }

            return '-';
          },
        },
        {
          key: 'created_by',
          label: this.$t('HISTORY.CREATED_BY'),
          tdClass: 'td-overflow',
          formatter: (_, __, item) => {

            if (item.data) {
              return item.data.user_id || item.data.member_id || item.data.admin_user_id;
            }

            return '-';
          },
        },

        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ]
    }
  },
  data() {
    return {
      orderBy: {
        created_at: 'desc'
      },
      total_rows: 0,
      filters: {},
      current_page: 1,
      per_page: 100,

      user_id: null,
      member_id: null,

      selected_history: null
    };
  },
  watch: {
    group() {
      this.refresh();
    }
  },
  mounted() {

  },
  methods: {
    history_selected(item) {
      this.selected_history = item;

      this.$nextTick(()=>{
        this.$refs['historyEditorModal'].show();
      });

    },

    on_filtered() {},

    show_user_or_member(item) {

      if (item.data.user_id) {
        this.user_id = item.data.user_id;
        this.member_id = null;
        this.$refs['selectUserOrMember'].show();
      }
      else if (item.data.member_id) {
        this.member_id = item.data.member_id;
        this.user_id = null;
        this.$refs['selectUserOrMember'].show();
      }
    },

    async get_user(user_id) {
      try {
        const res = await axios.get(`/user/${user_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('HISTORY.UNABLE_GET_USER'));
      }

      return null;
    },

    async get_member(member_id) {
      try {
        const res = await axios.get(`/member/${member_id}`);

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('HISTORY.UNABLE_GET_MEMBER'));
      }

      return null;
    },

    onFiltered(filteredItems) {},

    get_options() {
      const options = {};


      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      return options;
    },

    refresh() {
      this.$refs['historyTable'].refresh();
    },

    get_page_count() {

      const options = this.get_options();

      axios
        .post(`/history/pagination/count`, { group: this.group, options: options, orderBy: this.orderBy })
        .then(res => {
          this.total_rows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta antal emails');
        });
    },

    async ajax_pagination(ctx) {
      const vm = this;
      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.perPage,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      const options = this.get_options();

      this.get_page_count();

      try {
        const res = await axios.post(`/history/pagination`, {
          group: this.group,
          options: options,
          orderBy: this.orderBy,
          page: this.current_page,
          limit: this.per_page
        });

        return res.data || [];
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_PAGE'));
        console.error('pagination get error', err);
        return [];
      }
    },

  }
};
</script>

<style lang="scss" scoped>
:deep .td-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
</style>
